import { db } from '@/config/firebase.config.js';

export const fetchUserProducts = (id) => {
  return db
    .collection('user-products')
    .where('userId', '==', id)
    .orderBy('createdAt', 'desc')
    .get();
};

export const fetchProductById = (id) => {
  return db
    .collection('user-products')
    .doc(id)
    .get();
};
