<template>
  <div>
    <Search
      :fetchItemDetails="fetchItemDetails"
      :searchItems="searchItems"
      :fetchItemsCount="fetchItemsCount"
    />
  </div>
</template>
<script>
import Search from "../../apps/components/search/Search.vue";
import { fetchPlaylists, fetchPlaylistDetails } from "@/api/isrcApi";
import { fetchProductById } from "@/api/productApi";
import { onAuthStateChanged } from "@/api/authApi";
export default {
  name: "PublisherData",
  components: {
    Search,
  },
  props: {
    productId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      initialised: false,
    };
  },
  async created() {
    if (!this.productId) {
      this.$router.push("/dashboard");
      return;
    }
    const user = await onAuthStateChanged();
    if (!user) {
      this.$router.push("/");
      return;
    }
    this.initialised = true;
  },
  methods: {
    async fetchProduct(productId) {
      const productSnap = await fetchProductById(productId);
      if (!productSnap.exists) {
        throw new Error("DOES_NOT_EXIST");
      }
      const product = productSnap.data();
      return product;
    },
    async searchItems({ query, page }) {
      if (!this.product) {
        this.product = await this.fetchProduct(this.productId);
      }

      const playlists = await fetchPlaylists({
        isrc_ids: this.product.matchedCodes,
        productId: this.productId,
        query,
        page,
      });
      this.publisherData = playlists;
      return playlists;
    },
    async fetchItemsCount() {
      const product = await this.fetchProduct(this.productId);
      return {
        matchedCodesCount: product.matchedCodesCount,
      };
    },
    async fetchItemDetails(isrc_ids) {
      const response = await fetchPlaylistDetails(isrc_ids, this.productId);
      return response;
    },
  },
};
</script>
<style>
</style>